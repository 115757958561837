@import "@/styles/_variables.scss";






























































.content-wrapper {
  padding: 20px 20px 60px 20px;
  // min-height: 89vh;
}

.uk-notification-close {
  display: block;
  color: var(--white-color);
  right: 5px;
  top: 8px;
  margin-right: 5px;
}

.message {
  right: 10px;
}

.uk-notification-message .uk-grid-divider {
  margin-left: -80px;
}
